<template>
  <div class="vi-login">
    <main id="vi-login">
      <svg width="100%" height="450" class="web-bg-svg">
        <rect
          width="150%"
          height="450"
          style="fill:rgb(41, 84, 162);"
          transform="rotate(-13 0,750) skewX(8)"
        ></rect>
      </svg>
      <div class="container">
        <div class="row mt-4 mb-5">
          <div class="col-md-6">
            <img
              src="../../assets/img/login_3.svg"
              width="510"
              class="img-fluid heroimg mt-4"
              alt="Login"
            />
          </div>
          <div class="col-md-6">
            <div class="card web-login-card p-4 mt-2 mb-5" v-if="linkAvuabile && isAllow">
             
              <div class="forgot-password" >
                <h1>Change Password</h1>
                <!-- <p class="m-0">
                  Enter the email Address which is associated with you account.
                </p>
                -->
                <form class="w-100" data-vv-scope="passWordValidate" id="addteacher" accept-charset="utf-8">
                  <div class="form-row m-0">
                    <div class="form-group input-group mt-3">
                      <span class="web-has-float-label">
                        <input
                          name="password"
                          ref="password"
                          type="password"
                          class="form-control form-input"
                          v-validate="'required'"
                          v-model="password"
                        />
                        <label
                            v-if="errors.first('passWordValidate.password')"
                            class="vi-error"
                            for="emailid"
                            style="top:-1em"
                            >{{ errors.first('passWordValidate.password') }}</label
                        >
                       
                        <label for="passwordid" v-else
                          >New Password<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group mt-3">
                      <span class="web-has-float-label">
                        <input
                         type="password"
                        name="Confirm new Password"
                        data-vv-as="confirm new password"
                        class="form-control form-input"
                        v-validate="'required|confirmed:password'"
                        v-model="confirmPassWord"
                        />
                        <label
                            v-if="errors.first('passWordValidate.Confirm new Password')"
                            class="vi-error"
                            for="emailid"
                            style="top:-1em"
                            >{{ errors.first('passWordValidate.Confirm new Password') }}</label
                        >
                       
                        <label for="uemailid"  v-else
                          >Confirm New Password <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <button
                      type="submit"
                      class="web-custbutton mt-3 w-100"
                      @click.prevent="changePassword"
                    >
                      Send
                    </button>

                    <!-- <p class="form-group mt-1 w-100 text-center">
                      we will email you a link to reset your password.
                    </p>
                    <span class="d-block text-center w-100"
                      >Go back to
                      <a
                        href="javascript:void(0);"
                        class="forgot-password-link "
                        @click="goback"
                        >Login</a
                      ></span
                    > -->
                  </div>
                </form>
              </div>
              <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
            </div>
             <div v-if="!linkAvuabile && isAllow" class="card web-login-card p-4 mt-2 mb-5">
             
              <div class="forgot-password" >
                <h1>verification link expired.Please contact your school administrator</h1>
                <!-- <p class="m-0">
                  Enter the email Address which is associated with you account.
                </p>
                -->
                <button
                      type="submit"
                      class="web-custbutton mt-3 w-100"
                      @click="backToHome"
                    >
                      Back to Home
                    </button>
              </div>
             
              <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- End #main -->
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "@/utils/secureUI";
export default {
  name: "vi-login",
  components: {
    ViSpinner,
  },
  data() {
    return {
      linkAvuabile: false,
      isAllow: false,
      password: '',
      confirmPassWord: '',
      userDetails: {}
    };
  },
  created() {
    this.checkTokenIsValid();
  },
  methods: {
    redirectRegister() {
      if (this.$route.path !== "/register")
        this.$router.push({ path: "/register" });
    },
    async changePassword() {
      let isFormValid = false;

      await this.$validator.validateAll('passWordValidate').then(result => {
          isFormValid = result;
      });
      if (isFormValid) {

        let requestBody = {
          emailId: this.userDetails.user.email,
          _id: this.userDetails.user._id,
          password: this.password
        };
        
        ViService.viPost("/forgot-password/changePasswordAndVerifyLink", requestBody)
          .then((res) => {
            if (res.isSuccess) {
              this.$toasted.success('Password Changed Successfully');
              this.$router.push('/login');
            }else {
                this.$toasted.error(res.message);   
            } 
           
          })
          .catch((e) => {
           this.$toasted.error(e);   
          });
      }
    },
     async checkTokenIsValid() {
      
        const response = await ViService.viGetOnly(`/forgot-password/verifyToken?userToken=${this.$route.query.user_id}`);
        
       if (response.isSuccess) {
          this.userDetails = response.data
          this.linkAvuabile = true
        } else {
          this.$toasted.error(response.message);
        }
        this.isAllow = true
    },
    backToHome(){
        this.$router.push('/');
    }
   
  },
};
</script>

<style lang="css" scoped>
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 10px 0;
  margin-top: 70px;
}
.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 300;
  color: #fff;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
  color: #e7e7e7;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4f5a62;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: #f7f7f7;
}

.web-section-title {
  text-align: center;
  padding-bottom: 30px;
}

.web-section-title h2 {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}
.web-section-title h2 span {
  color: #2954a2;
}

.web-section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.web-section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #2954a2;
  bottom: 0;
  left: calc(50% - 20px);
}

.web-section-title p {
  margin-bottom: 0;
}
.web-whitebg {
  background: #fff;
  border-radius: 6px;
  transition: 0.3s all;
  border-top: 2px solid #2954a2;
}
.web-whitebg:hover {
  box-shadow: 0 0 3px #ccc;
}
.web-row-flex {
  display: flex;
  flex-wrap: wrap;
}
.content {
  height: 100%;
  padding: 12px;
}
.fit-image {
  width: 100%;
  object-fit: cover;
}
.logincont span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 0.85rem;
}
.web-loginbg {
  background: url("../../assets/images/login/loginbg.jpg");
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-size: cover;
  background-position: center;
}
.line {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin-top: 10px;
  position: relative;
}
.line span.or {
  width: 10%;
  font-weight: bold;
  position: absolute;
  top: -10px;
  background: #fff;
  text-align: center;
}
.fieldcontent {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-bottom: 10px;
}
.vi-form-control {
  border: none;
  border-bottom: 1px solid #2954a2;
  border-radius: 0px;
}
</style>
